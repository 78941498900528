var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      class: { "schedule-full-screen": _vm.isFullScreen },
      staticStyle: { height: "100%" },
      attrs: { xs6: "", "pa-0": "" }
    },
    [
      _c(
        "v-card",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            height: "100%"
          }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "justify-content-between pb-2" },
            [
              _c("span", { staticClass: "grow" }, [_vm._v("Schedule Board")]),
              _c(
                "draggable",
                {
                  staticClass: "dragAreaMaintenance",
                  attrs: {
                    group: { name: "people", put: false, pull: "clone" },
                    list: _vm.maintenanceObject
                  },
                  on: {
                    start: function($event) {
                      return _vm.mapMaintenanceToSchedule()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        border: "1px solid #ddd",
                        padding: "4px 6px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "align-center",
                          staticStyle: { display: "flex" }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-equal")]
                          ),
                          _c("small", [
                            _vm._v(
                              "Downtime (drag to schedule, then select hours)"
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleFullScreen } },
                [
                  _c("v-icon", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isFullScreen
                            ? "mdi-fullscreen-exit"
                            : "mdi-fullscreen"
                        ) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "schedule-table",
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "flex-grow": "1",
                overflow: "hidden"
              }
            },
            [
              _c("div", { staticClass: "d-flex align-center t-row t-head" }, [
                _c("div", [_vm._v("Product Description")]),
                _vm.isFullScreen
                  ? _c("div", [_vm._v("Sales Order #")])
                  : _vm._e(),
                _c("div", [_vm._v("Customer")]),
                _vm.isFullScreen
                  ? _c("div", [_vm._v("Customer PO#")])
                  : _vm._e(),
                _c("div", [_vm._v("Qty to Produce")]),
                _c("div", [_vm._v("Current Run Rate")]),
                _c("div", [_vm._v("Hours/Days")]),
                _c("div", [_vm._v("WO #")]),
                _vm.isFullScreen
                  ? _c("div", [_vm._v("Requested Date")])
                  : _vm._e(),
                _c("div", [_vm._v("Run Date/Time")]),
                _c("div")
              ]),
              _vm.isLoadingScheduleBoard
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: _vm.isLoadingScheduleBoard,
                      active: _vm.isLoadingScheduleBoard,
                      color: "blue"
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "t-body",
                  staticStyle: {
                    "overflow-y": "scroll",
                    "overflow-x": "auto",
                    "flex-grow": "1"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "t-group" },
                    [
                      _c(
                        "v-expansion-panel",
                        {
                          attrs: { expand: true, multiple: "" },
                          model: {
                            value: _vm.expandedLines,
                            callback: function($$v) {
                              _vm.expandedLines = $$v
                            },
                            expression: "expandedLines"
                          }
                        },
                        _vm._l(_vm.activeLines, function(item, indexOfLine) {
                          return _c(
                            "v-expansion-panel-content",
                            {
                              key: indexOfLine,
                              style: _vm.isDraggable(item - 1),
                              attrs: { "hide-actions": "", lazy: "" },
                              nativeOn: {
                                dragover: function($event) {
                                  return _vm.expandCompatibleLine(indexOfLine)
                                },
                                dragenter: function($event) {
                                  return _vm.startExpandTimer(indexOfLine)
                                },
                                dragleave: function($event) {
                                  return _vm.resetExpandTimer(indexOfLine)
                                },
                                dragend: function($event) {
                                  return _vm.stopTimer.apply(null, arguments)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          "\n                Line " +
                                            _vm._s(item) +
                                            "\n                "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-2",
                                            staticStyle: { color: "#1078ce" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.poundsPerHour(item - 1)
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "v-card",
                                { style: _vm.isLastLine(indexOfLine) },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      staticClass: "dragAreaScheduleBoard",
                                      staticStyle: { "min-height": "15px" },
                                      attrs: {
                                        list: _vm.getScheduleBoards(item - 1),
                                        group: { name: "people", pull: true },
                                        disabled: _vm.isDisabledLines(item - 1)
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.addToScheduleBoard(
                                            $event,
                                            item - 1
                                          )
                                        },
                                        start: function($event) {
                                          return _vm.isScheduleBoardDrag(
                                            $event,
                                            indexOfLine
                                          )
                                        },
                                        end: function($event) {
                                          return _vm.resetIsDraggable()
                                        }
                                      }
                                    },
                                    _vm._l(
                                      _vm.getScheduleBoards(item - 1),
                                      function(data, indexOfData) {
                                        return _c(
                                          "div",
                                          {
                                            key: indexOfData,
                                            staticClass: "t-row row-order",
                                            attrs: {
                                              id:
                                                data.openOrderId +
                                                "-" +
                                                data.salesItemNumber +
                                                "-" +
                                                data.productNumber +
                                                "-schedule"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.showDemandSource(
                                                  data
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      left: "",
                                                      lazy: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-equal"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _c(
                                                                    "schedule-product-text",
                                                                    {
                                                                      attrs: {
                                                                        "is-maintenance":
                                                                          data.isMaintenance,
                                                                        "downtime-reason":
                                                                          data.downTimeReason,
                                                                        "product-description":
                                                                          data.productDescription,
                                                                        "product-spec":
                                                                          data.productSpecification
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "schedule-product-text",
                                                      {
                                                        attrs: {
                                                          "is-maintenance":
                                                            data.isMaintenance,
                                                          "downtime-reason":
                                                            data.downTimeReason,
                                                          "product-description":
                                                            data.productDescription,
                                                          "product-spec":
                                                            data.productSpecification
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm.isFullScreen
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(data.orderNumber) +
                                                      "-" +
                                                      _vm._s(
                                                        data.salesItemNumber
                                                      )
                                                  )
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      right: "",
                                                      lazy: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.customer
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(data.customer)
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm.isFullScreen
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(data.purchaseOrder)
                                                  )
                                                ])
                                              : _vm._e(),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  data.updatedQuantityToProduce
                                                )
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatRunRate(
                                                    data.currentRunRate
                                                  )
                                                )
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.convertToDays(data.hours)
                                                )
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.displayWorkOrder(
                                                        indexOfLine,
                                                        indexOfData
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      lazy: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              data.failedToExportXml
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        staticStyle: {
                                                                          color:
                                                                            "#ff9800",
                                                                          "font-size":
                                                                            "15px"
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            mdi-alert-circle-outline\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " Failed to Export Work Order XML "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm.isFullScreen
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatDateStringWithoutTime(
                                                        data.requiredDate
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "align-center",
                                                class: {
                                                  "justify-end": !_vm.isFullScreen
                                                },
                                                staticStyle: { display: "flex" }
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      left: "",
                                                      lazy: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatDateStringWithTime(
                                                                        data.runDate
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatDateStringWithTime(
                                                            data.runDate
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      left: "",
                                                      lazy: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    staticStyle: {
                                                                      "font-size":
                                                                        "10px"
                                                                    },
                                                                    style: _vm.productionIndicator(
                                                                      data
                                                                    )
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                            mdi-checkbox-blank-circle\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Requested Date: " +
                                                          _vm._s(
                                                            _vm.formatDateStringWithoutTime(
                                                              data.requiredDate
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "details",
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.showDetailsLine(
                                                      indexOfLine,
                                                      indexOfData
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      lazy: "",
                                                      bottom: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ma-0",
                                                                    attrs: {
                                                                      small: "",
                                                                      flat: "",
                                                                      icon: "",
                                                                      color:
                                                                        "dark"
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "more_vert"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _vm.shouldShowDelete
                                                          ? _c(
                                                              "v-list-tile",
                                                              {
                                                                attrs: {
                                                                  disabled: !_vm.hasWriteAccess
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteScheduledItem(
                                                                      indexOfLine,
                                                                      indexOfData
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-tile-title",
                                                                  [
                                                                    _vm._v(
                                                                      "Delete Scheduled Item"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.isMaintenanceItem
                                                          ? _c(
                                                              "v-list-tile",
                                                              {
                                                                attrs: {
                                                                  disabled: !_vm.hasWriteAccess
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.editDowntime(
                                                                      indexOfLine,
                                                                      indexOfData
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-tile-title",
                                                                  [
                                                                    _vm._v(
                                                                      " Edit Downtime "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !_vm.isMaintenanceItem
                                                          ? _c(
                                                              "v-list-tile",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openWorkOrder(
                                                                      indexOfLine,
                                                                      indexOfData
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-tile-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.displayWorkOrderMenuText(
                                                                          indexOfLine,
                                                                          indexOfData
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                { staticStyle: { "border-top": "1px solid #ccc" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-0",
                      attrs: {
                        color: "white",
                        small: "",
                        tile: "",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.collapseAll()
                        }
                      }
                    },
                    [_vm._v("Collapse All")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-0",
                      attrs: {
                        color: "white",
                        small: "",
                        tile: "",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.expandAll()
                        }
                      }
                    },
                    [_vm._v("Expand All")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("modal-confirmation", {
            attrs: {
              message: "Are you sure you want to delete this scheduled item?"
            },
            on: { "confirmation-click": _vm.onDeleteConfirm },
            model: {
              value: _vm.showDeleteConfirmation,
              callback: function($$v) {
                _vm.showDeleteConfirmation = $$v
              },
              expression: "showDeleteConfirmation"
            }
          }),
          _c("modal-confirmation", {
            attrs: {
              message:
                "Are you sure you want to mark this work order as completed?"
            },
            on: { "confirmation-click": _vm.onMarkConfirm },
            model: {
              value: _vm.showMarkWorkOrderConfirmation,
              callback: function($$v) {
                _vm.showMarkWorkOrderConfirmation = $$v
              },
              expression: "showMarkWorkOrderConfirmation"
            }
          })
        ],
        1
      ),
      _c("MaintenanceModal", {
        attrs: {
          hours: _vm.editedHours,
          reasonId: _vm.editedReasonId,
          reasonNotes: _vm.editedReasonNotes
        },
        on: {
          "save-changes": _vm.importHours,
          "modal-exit": _vm.canceledHours
        },
        model: {
          value: _vm.showMaintenanceModal,
          callback: function($$v) {
            _vm.showMaintenanceModal = $$v
          },
          expression: "showMaintenanceModal"
        }
      }),
      _c("ProductionRateModal", {
        on: {
          "save-production-rate": _vm.importRate,
          "modal-exit-rate": _vm.canceledRate
        },
        model: {
          value: _vm.showProductionRateModal,
          callback: function($$v) {
            _vm.showProductionRateModal = $$v
          },
          expression: "showProductionRateModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }