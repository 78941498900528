var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "75%", lazy: "", fullscreen: _vm.isResponsive },
      on: { "esc-press": _vm.onEscPressed },
      model: {
        value: _vm.showProductSpecsModal,
        callback: function($$v) {
          _vm.showProductSpecsModal = $$v
        },
        expression: "showProductSpecsModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              "Product Specs For: " +
                _vm._s(_vm.currentProductSpecs.productDescription)
            )
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { staticClass: "mx-3" }, [
                    _c("h2", { staticClass: "mb-2" }, [
                      _vm._v("Product Details")
                    ]),
                    _c("table", { staticClass: "table" }, [
                      _c("thead"),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Product Number: ")])]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.currentProductSpecs.productNumber)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("b", [_vm._v("Product Description: ")])
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.currentProductSpecs.productDescription)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("b", [_vm._v("Primary Application: ")])
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.currentProductSpecs.primaryApplication)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("b", [_vm._v("Secondary Application: ")])
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.currentProductSpecs.secondaryApplication
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Lb/Ft: ")])]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm.currentProductSpecs.lbFt))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Min Wall: ")])]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm.currentProductSpecs.minWall))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Max Wall: ")])]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm.currentProductSpecs.maxWall))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Min OD: ")])]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm.currentProductSpecs.minOd))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Nom OD: ")])]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm.currentProductSpecs.nomOd))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Max OD: ")])]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm.currentProductSpecs.maxOd))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("OOR: ")])]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm.currentProductSpecs.oor))
                          ])
                        ]),
                        _vm.demandSummaryType
                          ? _c("tr", [
                              _c("td", [_c("b", [_vm._v("Conduit Type: ")])]),
                              _c("td", { staticClass: "text-xs-right" }, [
                                _vm._v(
                                  _vm._s(_vm.currentProductSpecs.conduitTape)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.demandSummaryType
                          ? _c("tr", [
                              _c("td", [_c("b", [_vm._v("Reel Type: ")])]),
                              _c("td", { staticClass: "text-xs-right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentProductSpecs.conduitReelType
                                  )
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.demandSummaryType
                          ? _c("tr", [
                              _c("td", [_c("b", [_vm._v("Reel Size: ")])]),
                              _c("td", { staticClass: "text-xs-right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentProductSpecs.conduitReelSize
                                  )
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c("v-flex", { staticClass: "mx-3" }, [
                    _c("h2", { staticClass: "mb-2" }, [
                      _vm._v("Production Line Run Rates")
                    ]),
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("th", { staticClass: "text-xs-left" }, [
                          _vm._v("Production Line")
                        ]),
                        _c("th", {
                          staticClass: "text-xs-left",
                          staticStyle: { width: "25px" }
                        }),
                        _c(
                          "th",
                          {
                            staticClass: "text-xs-right",
                            staticStyle: { width: "100px" }
                          },
                          [_vm._v("Run Rate")]
                        ),
                        _c("th", [_vm._v("Estimated Run Time")])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.currentProductRunRates, function(val, i) {
                          return _c("tr", { key: i }, [
                            _c("td", [
                              _vm._v("Line " + _vm._s(val.lineNumber))
                            ]),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    staticStyle: { "font-size": "10px" },
                                    style:
                                      val.isSupported == false
                                        ? "color: #f44336!important;"
                                        : "color: #4caf50!important;"
                                  },
                                  [
                                    _vm._v(
                                      "mdi-checkbox-blank-circle\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(
                                _vm._s(val.productionRateValue) + " Lbs/Hr"
                              )
                            ]),
                            _c("td", { staticClass: "text-xs-center" }, [
                              _vm._v(_vm._s(_vm.getRunTimeString(val)))
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ]),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c("v-flex", { staticClass: "mx-3" }, [
                    _c("h2", { staticClass: "mb-2" }, [
                      _vm._v("Order Details")
                    ]),
                    _c("table", { staticClass: "table" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Customer: ")])]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.customer))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Customer PO#: ")])]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.purchaseOrder))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Sales Order: ")])]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.salesOrder))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Ship-to-City: ")])]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.shipToCity))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Ship-to-State: ")])]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.shipToState))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Requested Date: ")])]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(
                                  _vm.currentOrderDetail.requestedDate
                                )
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Requested Qty (FT): ")])]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.currentOrderDetail.requestedQuantityInFeet.toLocaleString()
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_c("b", [_vm._v("Requested Qty (LB): ")])]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.currentOrderDetail.requestedQuantityInLbs.toLocaleString()
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [
                            _c("b", [_vm._v("Special Instructions: ")])
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.currentOrderDetail.specialInstructions)
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "error" },
                  on: {
                    click: function($event) {
                      _vm.isOpen = false
                    }
                  }
                },
                [_vm._v("Exit")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }