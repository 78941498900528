var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { right: "", lazy: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("div", [
                _c("span", _vm._g({}, on), [
                  _vm._v(_vm._s(_vm.workOrderInformation))
                ])
              ])
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v(_vm._s(_vm.workOrderInformation))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }