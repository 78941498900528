import { render, staticRenderFns } from "./CustomerInformation.vue?vue&type=template&id=598e38f0&"
import script from "./CustomerInformation.vue?vue&type=script&lang=ts&"
export * from "./CustomerInformation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\WlpScheduler\\front-end\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('598e38f0')) {
      api.createRecord('598e38f0', component.options)
    } else {
      api.reload('598e38f0', component.options)
    }
    module.hot.accept("./CustomerInformation.vue?vue&type=template&id=598e38f0&", function () {
      api.rerender('598e38f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/schedule/CustomerInformation.vue"
export default component.exports