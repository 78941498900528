var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticStyle: { margin: "24px" }, attrs: { raised: "", elevation: "3" } },
    [
      _c("v-card-title", [_vm._v("Packaging Details")]),
      _vm.savedPackageData.packagingCalculation
        ? _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.remainingBalance,
                          label: "Qty to Produce:",
                          readonly: "",
                          box: "",
                          "full-width": ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "pl-3", attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.parseHelper(_vm.info.cutLength),
                          label: "Cut Length:",
                          readonly: "",
                          box: "",
                          "full-width": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.defaultPackageData.jointsNeeded,
                          label: "Pieces Needed:",
                          readonly: "",
                          box: "",
                          "full-width": ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "pl-3", attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.info.productSpec,
                          label: "Product Spec",
                          readonly: "",
                          box: "",
                          "full-width": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.useOldUi
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              disabled: !_vm.hasWriteAccess,
                              color: "success",
                              label: "Override Packaging"
                            },
                            model: {
                              value: _vm.savedPackageData.override,
                              callback: function($$v) {
                                _vm.$set(_vm.savedPackageData, "override", $$v)
                              },
                              expression: "savedPackageData.override"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.useOldUi
                ? _c("v-layout", { staticClass: "pb-2" }, [
                    _c("table", [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { staticStyle: { "text-align": "left" } }, [
                            _vm._v("Bundle Type")
                          ]),
                          _c("th", { staticStyle: { "text-align": "left" } }, [
                            _vm._v("Bundles Needed")
                          ]),
                          _c("th", { staticStyle: { "text-align": "left" } }, [
                            _vm._v("Joints Per Bundle")
                          ]),
                          _c("th", { staticStyle: { "text-align": "left" } }, [
                            _vm._v("Bundle Dimensions")
                          ]),
                          _c("th", { staticStyle: { "text-align": "left" } }, [
                            _vm._v("Bands")
                          ])
                        ])
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("Large")]),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  suffix: "of",
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bundlesNeeded,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bundlesNeeded",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bundlesNeeded"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .jointsPerBundle,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "jointsPerBundle",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.jointsPerBundle"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bundleDimensions,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bundleDimensions",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bundleDimensions"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bands,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bands",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bands"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Small")]),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  suffix: "of",
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bundlesNeeded2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bundlesNeeded2",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bundlesNeeded2"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .jointsPerBundle2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "jointsPerBundle2",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.jointsPerBundle2"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bundleDimensions2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bundleDimensions2",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bundleDimensions2"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bands2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bands2",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bands2"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "pr-3" }, [
                            _vm._v("Non-standard")
                          ]),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  suffix: "of",
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bundlesNeeded3,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bundlesNeeded3",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bundlesNeeded3"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .jointsPerBundle3,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "jointsPerBundle3",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.jointsPerBundle3"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bundleDimensions3,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bundleDimensions3",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bundleDimensions3"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "pr-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.savedPackageData.override,
                                  disabled: !_vm.hasWriteAccess
                                },
                                model: {
                                  value:
                                    _vm.savedPackageData.packagingCalculation
                                      .bands3,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.savedPackageData.packagingCalculation,
                                      "bands3",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "savedPackageData.packagingCalculation.bands3"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.savedPackageData.override,
                            expression: "savedPackageData.override"
                          }
                        ],
                        staticClass: "pl-5",
                        staticStyle: { "text-align": "center" }
                      },
                      [
                        _c("div", { staticClass: "display-1" }, [
                          _vm._v("Piece Count")
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "headline",
                            class: {
                              "wrong-pieces":
                                _vm.piecesCalculation != _vm.piecesNeeded
                            }
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(_vm.piecesCalculation)
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.useOldUi
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Pieces Per Bundle:",
                              readonly: !_vm.jointsPerBundleOverride,
                              disabled: !_vm.hasWriteAccess
                            },
                            model: {
                              value: _vm.savedPackageData.jointsPerBundle,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.savedPackageData,
                                  "jointsPerBundle",
                                  $$v
                                )
                              },
                              expression: "savedPackageData.jointsPerBundle"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-3", attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              disabled: !_vm.hasWriteAccess,
                              color: "success",
                              label: "Override Pieces/Bundle"
                            },
                            model: {
                              value: _vm.jointsPerBundleOverride,
                              callback: function($$v) {
                                _vm.jointsPerBundleOverride = $$v
                              },
                              expression: "jointsPerBundleOverride"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.useOldUi
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Pieces/Bundles Needed:",
                              readonly: !_vm.jbOverride,
                              disabled: !_vm.hasWriteAccess
                            },
                            model: {
                              value: _vm.savedPackageData.jointsPerBundleNeeded,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.savedPackageData,
                                  "jointsPerBundleNeeded",
                                  $$v
                                )
                              },
                              expression:
                                "savedPackageData.jointsPerBundleNeeded"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-3", attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              disabled: !_vm.hasWriteAccess,
                              color: "success",
                              label: "Override Pieces/Bundles Needed"
                            },
                            model: {
                              value: _vm.jbOverride,
                              callback: function($$v) {
                                _vm.jbOverride = $$v
                              },
                              expression: "jbOverride"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.useOldUi
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Bundle Dimensions:",
                              readonly: !_vm.bundleDimensionsOverride,
                              disabled: !_vm.hasWriteAccess
                            },
                            model: {
                              value: _vm.savedPackageData.bundleDimensions,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.savedPackageData,
                                  "bundleDimensions",
                                  $$v
                                )
                              },
                              expression: "savedPackageData.bundleDimensions"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-3", attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              disabled: !_vm.hasWriteAccess,
                              color: "success",
                              label: "Override Bundle Dimensions"
                            },
                            model: {
                              value: _vm.bundleDimensionsOverride,
                              callback: function($$v) {
                                _vm.bundleDimensionsOverride = $$v
                              },
                              expression: "bundleDimensionsOverride"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.useOldUi
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Bands:",
                              readonly: !_vm.bandsOverride,
                              disabled: !_vm.hasWriteAccess
                            },
                            model: {
                              value: _vm.savedPackageData.bands,
                              callback: function($$v) {
                                _vm.$set(_vm.savedPackageData, "bands", $$v)
                              },
                              expression: "savedPackageData.bands"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-3", attrs: { xs6: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              disabled: !_vm.hasWriteAccess,
                              color: "success",
                              label: "Override Bands"
                            },
                            model: {
                              value: _vm.bandsOverride,
                              callback: function($$v) {
                                _vm.bandsOverride = $$v
                              },
                              expression: "bandsOverride"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }