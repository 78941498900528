var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-form",
    {
      attrs: { title: _vm.createdConduitDemand.formTitle, "max-width": "600" },
      on: {
        "save-click": _vm.saveDemandSummary,
        "esc-press": _vm.onEscPressed
      },
      model: {
        value: _vm.showScheduleModal,
        callback: function($$v) {
          _vm.showScheduleModal = $$v
        },
        expression: "showScheduleModal"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "scheduleForm",
          model: {
            value: _vm.createdConduitDemand.isModelValid,
            callback: function($$v) {
              _vm.$set(_vm.createdConduitDemand, "isModelValid", $$v)
            },
            expression: "createdConduitDemand.isModelValid"
          }
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          hint: "Select Product",
                          label: "Products List",
                          items: _vm.createdConduitDemand.productsSelectList,
                          rules: _vm.createdConduitDemand.isRequiredRules,
                          filter: _vm.filterDescriptionOrNumber
                        },
                        on: {
                          change: function($event) {
                            return _vm.loadVariants()
                          }
                        },
                        model: {
                          value: _vm.productNumber,
                          callback: function($$v) {
                            _vm.productNumber = $$v
                          },
                          expression: "productNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Customer",
                          required: "",
                          rules: _vm.createdConduitDemand.isRequiredRules
                        },
                        model: {
                          value: _vm.customer,
                          callback: function($$v) {
                            _vm.customer = $$v
                          },
                          expression: "customer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.variantList.length > 0,
                            expression: "variantList.length > 0"
                          }
                        ],
                        attrs: {
                          items: _vm.variantList,
                          label: "Select Variant",
                          rules: _vm.createdConduitDemand.isRequiredRules
                        },
                        model: {
                          value: _vm.variant,
                          callback: function($$v) {
                            _vm.variant = $$v
                          },
                          expression: "variant"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Required Quantity",
                          type: "number",
                          required: "",
                          rules: _vm.createdConduitDemand.isRequiredRulesNumber
                        },
                        model: {
                          value: _vm.requiredQuantity,
                          callback: function($$v) {
                            _vm.requiredQuantity = $$v
                          },
                          expression: "requiredQuantity"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            lazy: "",
                            transition: "scale-transition",
                            "offset-y": "",
                            "full-width": "",
                            "min-width": "290px",
                            rules: _vm.createdConduitDemand.isRequiredRules
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          label: "Required Date",
                                          "prepend-icon": "event",
                                          readonly: "",
                                          rules:
                                            _vm.createdConduitDemand
                                              .isRequiredRules
                                        },
                                        model: {
                                          value: _vm.requiredDate,
                                          callback: function($$v) {
                                            _vm.requiredDate = $$v
                                          },
                                          expression: "requiredDate"
                                        }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.dateBox,
                            callback: function($$v) {
                              _vm.dateBox = $$v
                            },
                            expression: "dateBox"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            on: {
                              input: function($event) {
                                _vm.dateBox = false
                              }
                            },
                            model: {
                              value: _vm.requiredDate,
                              callback: function($$v) {
                                _vm.requiredDate = $$v
                              },
                              expression: "requiredDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }