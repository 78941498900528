var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { right: "", lazy: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                { staticClass: "client" },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-equal")
                  ]),
                  _c("span", _vm._g({}, on), [
                    _vm._v(_vm._s(_vm.source.customerInformation))
                  ]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "", lazy: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _vm.specialInstructionsIcon()
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "ml-1",
                                          staticStyle: {
                                            color: "#ff9800",
                                            "font-size": "15px"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "mdi-alert-circle-outline\r\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.specialInstructions()))])]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v(_vm._s(_vm.source.customerInformation))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }