var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.isModalOpen,
        fullscreen: "",
        persistent: "",
        transition: "dialog-bottom-transition"
      }
    },
    [
      _c(
        "v-stepper",
        {
          staticStyle: {
            height: "100%",
            display: "flex",
            "flex-direction": "column"
          },
          attrs: { value: _vm.currentStep },
          on: { input: _vm.setStepFromStepper }
        },
        [
          _c(
            "div",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary", height: "64" } },
                [
                  _c("v-toolbar-title", [_vm._v(" Work Order ")]),
                  _c("v-spacer"),
                  _c("span", { staticClass: "subheading" }, [
                    _vm._v(
                      _vm._s(_vm.workOrderInformation.product) +
                        " / " +
                        _vm._s(_vm.workOrderInformation.productSpec)
                    )
                  ]),
                  _c("v-spacer"),
                  _c("v-btn", { on: { click: _vm.decrementStep } }, [
                    _vm._v("Back")
                  ]),
                  !_vm.hasWriteAccess
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.checkSavedPackage
                          },
                          on: { click: _vm.incrementStep }
                        },
                        [_vm._v("Next")]
                      )
                    : _vm._e(),
                  _vm.hasWriteAccess
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.checkSavedPackage
                          },
                          on: { click: _vm.incrementStep }
                        },
                        [_vm._v(_vm._s(_vm.nextString))]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.currentStep = -1
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: { complete: "", editable: "", step: _vm.stepOne }
                    },
                    [_vm._v(" Production Info ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.currentStep > 1 || !_vm.shouldCreate,
                        editable: "",
                        step: _vm.stepTwo
                      }
                    },
                    [_vm._v("\n          Work Order Details\n        ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: !_vm.shouldCreate,
                        editable: !_vm.shouldCreate,
                        step: _vm.stepThree
                      }
                    },
                    [_vm._v("\n          Packaging Details\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            {
              staticStyle: { "overflow-y": "scroll" },
              attrs: { "align-content-center": "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { grow: "" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { md3: "" } },
                        [_c("BasicProductionDetails")],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md9: "" } },
                        [
                          _c(
                            "v-stepper-items",
                            [
                              _c(
                                "v-stepper-content",
                                {
                                  staticStyle: { padding: "0px" },
                                  attrs: { step: _vm.stepOne }
                                },
                                [
                                  _c("EditProdction", {
                                    attrs: {
                                      importedRate: _vm.currentRate,
                                      importedQty: _vm.currentQty,
                                      hasWorkOrder: _vm.shouldCreate
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticStyle: { padding: "0px" },
                                  attrs: { step: _vm.stepTwo }
                                },
                                [_c("v-spacer"), _c("ProductionDetails")],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                {
                                  staticStyle: { padding: "0px" },
                                  attrs: { step: _vm.stepThree }
                                },
                                [
                                  !_vm.shouldCreate
                                    ? _c("PackageDetails")
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-footer",
            {
              staticStyle: { "min-height": "64px" },
              attrs: { dark: "", color: "primary" }
            },
            [
              _c(
                "v-layout",
                {
                  staticClass: "mr-2",
                  attrs: {
                    "align-center": "",
                    "justify-end": "",
                    row: "",
                    "fill-height": ""
                  }
                },
                [
                  !_vm.shouldCreate && _vm.hasWriteAccess
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              _vm.showMarkWorkOrderConfirmation = true
                            }
                          }
                        },
                        [_vm._v("\n          Mark Complete\n        ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("modal-confirmation", {
        attrs: { message: "Are you sure you want to create this work order?" },
        on: { "confirmation-click": _vm.confirmSaveWorkOrder },
        model: {
          value: _vm.workOrderDialog,
          callback: function($$v) {
            _vm.workOrderDialog = $$v
          },
          expression: "workOrderDialog"
        }
      }),
      _c("modal-confirmation", {
        attrs: {
          message: "Are you sure you want to save this packaging data?"
        },
        on: { "confirmation-click": _vm.confirmSavePackagingData },
        model: {
          value: _vm.packagingDialog,
          callback: function($$v) {
            _vm.packagingDialog = $$v
          },
          expression: "packagingDialog"
        }
      }),
      _c("modal-confirmation", {
        attrs: {
          message: "Are you sure you want to mark this work order as completed?"
        },
        on: { "confirmation-click": _vm.markWorkOrderComplete },
        model: {
          value: _vm.showMarkWorkOrderConfirmation,
          callback: function($$v) {
            _vm.showMarkWorkOrderConfirmation = $$v
          },
          expression: "showMarkWorkOrderConfirmation"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }