var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            height: "calc(100vh - 64px)",
            padding: "0",
            position: "relative",
            background: "#f0f0f0"
          },
          attrs: { "grid-list-xl": "", fluid: "" },
          on: { mousemove: _vm.mouseMoving }
        },
        [
          _c(
            "div",
            { staticClass: "filter-alert" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "justify-center align-center py-1 px-3 mb-4",
                  attrs: { color: "error", icon: "warning", dismissible: "" },
                  model: {
                    value: _vm.showFiltersAlert,
                    callback: function($$v) {
                      _vm.showFiltersAlert = $$v
                    },
                    expression: "showFiltersAlert"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "align-center justify-between",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c("div", { staticStyle: { "flex-grow": "1" } }, [
                        _vm._v(
                          "\n            The current demand source could not be found, maybe due to your filters. Would you like to clear your\n            filters and try again?\n          "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "white", small: "" },
                          on: { click: _vm.retryShowDemandSource }
                        },
                        [_vm._v("Clear Filters")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("HeaderComponent", {
            on: {
              "destroy-session": function($event) {
                return _vm.resetSearch()
              }
            }
          }),
          _c(
            "v-layout",
            {
              staticStyle: {
                "flex-grow": "1",
                overflow: "hidden",
                margin: "0"
              },
              attrs: { row: "", wrap: "" }
            },
            [
              _c("DemandSummaryComponent", {
                attrs: {
                  demandSummaryHeaders: _vm.scheduleModel.demandSummaryHeaders,
                  searchFilter: _vm.searchFilter,
                  lineFilterTest: _vm.lineFilterTest
                }
              }),
              _c("ScheduleBoardComponent")
            ],
            1
          )
        ],
        1
      ),
      _c("HomeScheduleModal", {
        model: {
          value: _vm.showHomeScheduleModal,
          callback: function($$v) {
            _vm.showHomeScheduleModal = $$v
          },
          expression: "showHomeScheduleModal"
        }
      }),
      _c("WorkOrderModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }