var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { lazy: "", right: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "span",
                _vm._g(
                  _vm._b(
                    { style: [!_vm.hasDemandSources ? { color: "Red" } : {}] },
                    "span",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.productDescription) +
                      " " +
                      _vm._s(_vm.productSpecText) +
                      "\n    "
                  )
                ]
              )
            ]
          }
        }
      ])
    },
    [_vm._v("\n  " + _vm._s(_vm.productNumber) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }