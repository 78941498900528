var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800", lazy: "" },
      on: { "esc-press": _vm.closeScheduleProductModal },
      model: {
        value: _vm.showScheduleProductModal,
        callback: function($$v) {
          _vm.showScheduleProductModal = $$v
        },
        expression: "showScheduleProductModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("div", [
              _c("div", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.passedProduct.customer))
              ]),
              _c("span", { staticClass: "grey--text" }, [
                _vm._v(_vm._s(_vm.passedProduct.productDescription))
              ])
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "table",
                    {
                      staticClass: "schedule-table",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(_vm.productsModel.productsHeaders, function(
                            header
                          ) {
                            return _c("th", { key: header }, [
                              _vm._v(_vm._s(header))
                            ])
                          }),
                          0
                        )
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.scheduleLines, function(line, index) {
                          return _c("tr", { key: index }, [
                            _c(
                              "td",
                              [
                                _c("v-switch", {
                                  staticClass: "table-input",
                                  attrs: {
                                    color: "success",
                                    label: "Line " + line.lineNumber
                                  },
                                  model: {
                                    value: line.isEnabled,
                                    callback: function($$v) {
                                      _vm.$set(line, "isEnabled", $$v)
                                    },
                                    expression: "line.isEnabled"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm.checkRate(line, index)
                                  ? _c("v-text-field", {
                                      staticClass: "table-input",
                                      attrs: {
                                        suffix: "LBS/Hour",
                                        rules: _vm.isRequiredRulesNumber,
                                        required: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setRunTime(line, index)
                                        },
                                        keypress: function($event) {
                                          return _vm.isNumber($event)
                                        }
                                      },
                                      model: {
                                        value: line.productionRate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            line,
                                            "productionRate",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "line.productionRate"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm.checkRate(line, index)
                                  ? _c("v-text-field", {
                                      staticClass: "table-input",
                                      attrs: {
                                        suffix: "FT",
                                        rules: _vm.isRequiredRulesNumber,
                                        required: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setRunTime(line, index)
                                        },
                                        keypress: function($event) {
                                          return _vm.isNumber($event)
                                        }
                                      },
                                      model: {
                                        value: line.updatedQuantityToProduce,
                                        callback: function($$v) {
                                          _vm.$set(
                                            line,
                                            "updatedQuantityToProduce",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "line.updatedQuantityToProduce"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm.checkRate(line, index)
                                  ? _c("v-text-field", {
                                      staticClass: "table-input",
                                      attrs: { disabled: "", suffix: "HRS" },
                                      model: {
                                        value: line.runTime,
                                        callback: function($$v) {
                                          _vm.$set(line, "runTime", _vm._n($$v))
                                        },
                                        expression: "line.runTime"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      ),
                      _c("tfoot", [
                        _c("tr", [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c("strong", [_vm._v("Requested Quantity")])
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(this.passedProduct.quantityToProduce))
                          ]),
                          _c("td")
                        ]),
                        _c("tr", [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c("strong", [_vm._v("Total Quantity")])
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.setTotalQuantity()) + " FT")
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.setTotalRunTime()) + " HRS")
                          ])
                        ])
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pa-4 text-lg-right" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "light" },
                  on: {
                    click: function($event) {
                      _vm.isOpen = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.hasWriteAccess, color: "primary" },
                  on: { click: _vm.saveScheduleProduct }
                },
                [_vm._v("Schedule")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }