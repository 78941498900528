var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticStyle: { height: "100%" }, attrs: { xs6: "", "pa-0": "" } },
    [
      _c(
        "v-card",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            height: "100%"
          }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "justify-content-between align-center pb-0" },
            [
              _c("span", [_vm._v("Demand Summary")]),
              _c("v-spacer"),
              _vm.checkSupportConduit
                ? _c(
                    "div",
                    { staticClass: "type-switch grow text-center" },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "" },
                          model: {
                            value: _vm.demandSummarySelection,
                            callback: function($$v) {
                              _vm.demandSummarySelection = $$v
                            },
                            expression: "demandSummarySelection"
                          }
                        },
                        [
                          _c("v-radio", {
                            staticStyle: { "font-size": "10px" },
                            attrs: {
                              label: "Single Stage",
                              value: "pressurePipe"
                            }
                          }),
                          _c("v-radio", {
                            attrs: { label: "Multi Stage", value: "conduit" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "pa-0" },
                [
                  _c("span", { staticClass: "caption mr-2" }, [
                    _vm._v(_vm._s(_vm.lastSyncTime))
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-0 my-0 mr-2",
                      attrs: {
                        icon: "",
                        small: "",
                        color: "success",
                        disabled: !_vm.hasWriteAccess || _vm.buttonIsDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.getDemandSummaryBoard(
                            _vm.currentFacility,
                            true
                          )
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("refresh")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "pb-2", attrs: { xs7: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Search",
                          "hide-details": "",
                          dense: "",
                          "append-outer-icon": "mdi-send",
                          clearable: true
                        },
                        on: {
                          keypress: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchDemandSummary.apply(
                              null,
                              arguments
                            )
                          },
                          "click:clear": _vm.clearSearch,
                          "click:append-outer": _vm.searchDemandSummary
                        },
                        model: {
                          value: _vm.filterSearchText,
                          callback: function($$v) {
                            _vm.filterSearchText = $$v
                          },
                          expression: "filterSearchText"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "pb-2", attrs: { xs5: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "All Lines",
                          "hide-details": "",
                          dense: "",
                          items: _vm.activeLines,
                          clearable: true
                        },
                        on: {
                          "click:clear": _vm.clearLineSearch,
                          change: _vm.setLineFilter
                        },
                        model: {
                          value: _vm.filterLineText,
                          callback: function($$v) {
                            _vm.filterLineText = $$v
                          },
                          expression: "filterLineText"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "summary-table",
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "flex-grow": "1",
                overflow: "hidden",
                height: "100%"
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center t-row t-head" },
                _vm._l(_vm.demandSummaryHeaders, function(header) {
                  return _c("div", { key: header }, [_vm._v(_vm._s(header))])
                }),
                0
              ),
              _vm.isLoadingDemandSummary
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: _vm.isLoadingDemandSummary,
                      active: _vm.isLoadingDemandSummary,
                      color: "blue"
                    }
                  })
                : _vm._e(),
              _vm.checkPressurePipe
                ? _c(
                    "div",
                    {
                      staticClass: "t-body",
                      staticStyle: { overflow: "hidden", "flex-grow": "1" }
                    },
                    [
                      _c("DynamicScroller", {
                        staticStyle: { height: "100%" },
                        attrs: {
                          items: _vm.demandSummaryArray,
                          "min-item-size": 60,
                          keyField: "id",
                          buffer: 200
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var item = ref.item
                                var active = ref.active
                                return [
                                  _c(
                                    "DynamicScrollerItem",
                                    {
                                      attrs: {
                                        item: item,
                                        active: active,
                                        "size-dependencies": [
                                          item.demandSources
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "t-group" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "t-row row-product",
                                              class: {
                                                collapsed: _vm.isSingleStageLineCollapsed(
                                                  item.id
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleSingleStageLine(
                                                    item.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("ProductDemandInfo", {
                                                    attrs: {
                                                      "product-description":
                                                        item.productDescription,
                                                      "product-spec":
                                                        item.productSpec,
                                                      "product-number":
                                                        item.productNumber
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.isSingleStageLineCollapsed(
                                                          item.id
                                                        ),
                                                        expression:
                                                          "!isSingleStageLineCollapsed(item.id)"
                                                      }
                                                    ]
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.parseIntHelper(
                                                            item.totalInventory
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.isSingleStageLineCollapsed(
                                                          item.id
                                                        ),
                                                        expression:
                                                          "isSingleStageLineCollapsed(item.id)"
                                                      }
                                                    ]
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.parseIntHelper(
                                                          item.netReqQtyTotal
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _vm.isSingleStageLineCollapsed(
                                                item.id
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.parseIntHelper(
                                                              item.schedQtyTotal
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.isSingleStageLineCollapsed(
                                                item.id
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.parseIntHelper(
                                                              _vm.displayScheduledCheckQuantity(
                                                                item
                                                              )
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "ml-1",
                                                          style: _vm.scheduledCheckIcon(
                                                            item
                                                          )
                                                        },
                                                        [_vm._v("mdi-disc")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.isSingleStageLineCollapsed(
                                                item.id
                                              )
                                                ? _c("div", {
                                                    staticClass: "spacer"
                                                  })
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._l(item.demandSources, function(
                                            source
                                          ) {
                                            return _c(
                                              "draggable",
                                              {
                                                key: source.id,
                                                staticClass:
                                                  "dragAreaDemandSummary",
                                                attrs: {
                                                  list: _vm.getScheduleModuleDemandSummaries(),
                                                  group: {
                                                    name: "people",
                                                    pull: "clone",
                                                    put: false,
                                                    disabled: true
                                                  },
                                                  sort: false,
                                                  disabled: !_vm.hasWriteAccess
                                                },
                                                on: {
                                                  start: function($event) {
                                                    return _vm.mapToScheduleBoard(
                                                      $event,
                                                      source
                                                    )
                                                  },
                                                  end: function($event) {
                                                    return _vm.resetIsDraggable()
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.isSingleStageLineCollapsed(
                                                          item.id
                                                        ),
                                                        expression:
                                                          "!isSingleStageLineCollapsed(item.id)"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "t-row row-order selected-source pressure-pipe",
                                                    attrs: {
                                                      id:
                                                        source.openOrderId +
                                                        "-" +
                                                        source.salesItemNumber +
                                                        "-" +
                                                        item.productNumber
                                                    }
                                                  },
                                                  [
                                                    _c("CustomerInformation", {
                                                      attrs: { source: source }
                                                    }),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          source.salesOrderNumber
                                                        ) +
                                                          "-" +
                                                          _vm._s(
                                                            source.salesItemNumber
                                                          )
                                                      )
                                                    ]),
                                                    _c("WorkOrderInformation", {
                                                      attrs: { source: source }
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "align-center",
                                                        staticStyle: {
                                                          display: "flex"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            staticStyle: {
                                                              "font-size":
                                                                "10px"
                                                            },
                                                            style: _vm.requireDateIconDemandSummary(
                                                              source
                                                            )
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        mdi-checkbox-blank-circle\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm
                                                                .util()
                                                                .formatDateString(
                                                                  source.requiredDate
                                                                )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        "-" +
                                                          _vm._s(
                                                            _vm.parseIntHelper(
                                                              source.quantityRequested
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.showOnSchedule(
                                                              source.openOrderId,
                                                              source.salesItemNumber,
                                                              item.productNumber,
                                                              item.productDescription
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              cursor: "pointer"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.parseIntHelper(
                                                                  source.scheduledQuantity
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "details"
                                                      },
                                                      [
                                                        _c(
                                                          "DemandSummaryMenu",
                                                          {
                                                            attrs: {
                                                              "has-write-access":
                                                                _vm.hasWriteAccess
                                                            },
                                                            on: {
                                                              "view-product-specs": function(
                                                                $event
                                                              ) {
                                                                return _vm.viewProductSpecs(
                                                                  item,
                                                                  source
                                                                )
                                                              },
                                                              "schedule-product": function(
                                                                $event
                                                              ) {
                                                                return _vm.scheduleProduct(
                                                                  item,
                                                                  source
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isSingleStageLineCollapsed(
                                                    item.id
                                                  ),
                                                  expression:
                                                    "!isSingleStageLineCollapsed(item.id)"
                                                }
                                              ],
                                              staticClass: "t-row row-total"
                                            },
                                            [
                                              _c("div", [_vm._v("Total")]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.parseIntHelper(
                                                      item.netReqQtyTotal
                                                    )
                                                  )
                                                )
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.parseIntHelper(
                                                      item.schedQtyTotal
                                                    )
                                                  )
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "align-center",
                                                  staticStyle: {
                                                    display: "flex"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.parseIntHelper(
                                                          _vm.displayScheduledCheckQuantity(
                                                            item
                                                          )
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "ml-1",
                                                      style: _vm.scheduledCheckIcon(
                                                        item
                                                      )
                                                    },
                                                    [_vm._v("mdi-disc")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3645449833
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.checkPressurePipe
                ? _c(
                    "div",
                    {
                      staticClass: "t-body",
                      staticStyle: {
                        "overflow-y": "scroll",
                        "overflow-x": "auto",
                        "flex-grow": "1"
                      }
                    },
                    _vm._l(
                      _vm.conduitHelper.getFilteredArrayConduitNew(),
                      function(data, indexOfData) {
                        return _c(
                          "div",
                          {
                            key: data.productNumber + " - " + data.productSpec,
                            staticClass: "t-group"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "t-row row-product",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleMultiStageLine(
                                      data.productNumber +
                                        " - " +
                                        data.productSpec
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("ParentConduitProductInfo", {
                                      attrs: {
                                        "product-description":
                                          data.productDescription,
                                        "product-spec": data.productSpec,
                                        "product-number": data.productNumber,
                                        "has-demand-sources":
                                          data.conduitDemandSource !== null
                                      }
                                    })
                                  ],
                                  1
                                ),
                                data.conduitDemandSource === null
                                  ? _c("PendingComponent")
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "250px",
                                      "text-align": "left"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Total Demand: " +
                                        _vm._s(
                                          _vm.parseIntHelper(
                                            data.originalQuantityRequested
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "250px",
                                      "text-align": "left"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Total Inventory: " +
                                        _vm._s(
                                          _vm.parseIntHelper(
                                            data.totalInventory
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm.isMultiStageLineCollapsed(
                                  data.productNumber + " - " + data.productSpec
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "right" }
                                      },
                                      _vm._l(data.conduitDemandSource, function(
                                        source
                                      ) {
                                        return _c(
                                          "span",
                                          { key: source.productMasterId },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                style: _vm.conduitHelper.scheduledCheckIconConduit(
                                                  source
                                                )
                                              },
                                              [_vm._v("mdi-disc ")]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            !_vm.isMultiStageLineCollapsed(
                              data.productNumber + " - " + data.productSpec
                            )
                              ? _c(
                                  "div",
                                  _vm._l(data.conduitDemandSource, function(
                                    demandSource,
                                    indexOfDemandSource
                                  ) {
                                    return _c(
                                      "div",
                                      { key: demandSource.productMasterId },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "t-row row-order" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "customer",
                                                staticStyle: { width: "87%" }
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      lazy: "",
                                                      right: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        demandSource.productDescription
                                                                      ) +
                                                                      " - " +
                                                                      _vm._s(
                                                                        data.productSpec
                                                                      ) +
                                                                      "\n                        " +
                                                                      _vm._s(
                                                                        demandSource.conduitReelType
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        demandSource.conduitReelSize
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          demandSource.productNumber
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "customer" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.parseIntHelper(
                                                      demandSource.totalInventory
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("div", {
                                              staticClass: "customer"
                                            }),
                                            _c("div", {
                                              staticClass: "customer"
                                            })
                                          ]
                                        ),
                                        _vm._l(
                                          demandSource.customerInfo,
                                          function(customer, indexOfCustomer) {
                                            return _c(
                                              "draggable",
                                              {
                                                key: demandSource.id,
                                                staticClass:
                                                  "dragAreaDemandSummary",
                                                attrs: {
                                                  group: {
                                                    name: "people",
                                                    pull: "clone",
                                                    put: false,
                                                    disabled: true
                                                  },
                                                  sort: false,
                                                  list: _vm.getScheduleModuleConduitDemandsNew(),
                                                  disabled: !_vm.hasWriteAccess
                                                },
                                                on: {
                                                  start: function($event) {
                                                    return _vm.mapToScheduleBoardConduitNew(
                                                      $event,
                                                      indexOfData,
                                                      indexOfDemandSource,
                                                      indexOfCustomer
                                                    )
                                                  },
                                                  end: function($event) {
                                                    return _vm.resetIsDraggable()
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "t-row row-order selected-source conduit",
                                                    attrs: {
                                                      id:
                                                        customer.openOrderId +
                                                        "-" +
                                                        customer.salesItemNumber +
                                                        "-" +
                                                        demandSource.productNumber
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.showOnSchedule(
                                                          customer.openOrderId,
                                                          customer.salesItemNumber,
                                                          demandSource.productNumber,
                                                          demandSource.productDescription
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "client" },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: { small: "" }
                                                          },
                                                          [_vm._v("mdi-equal")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              customer.customerInformation
                                                            )
                                                          )
                                                        ]),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              right: "",
                                                              lazy: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _vm.conduitHelper.specialInstructionsIconConduit(
                                                                        indexOfData,
                                                                        indexOfDemandSource,
                                                                        indexOfCustomer
                                                                      )
                                                                        ? _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1",
                                                                                staticStyle: {
                                                                                  color:
                                                                                    "#ff9800",
                                                                                  "font-size":
                                                                                    "15px"
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-alert-circle-outline\n                        "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.conduitHelper.specialInstructionsConduit(
                                                                    indexOfData,
                                                                    indexOfDemandSource,
                                                                    indexOfCustomer
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          customer.salesOrderNumber
                                                        ) +
                                                          "-" +
                                                          _vm._s(
                                                            customer.salesItemNumber
                                                          )
                                                      )
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.conduitHelper.displayWorkOrderNumber(
                                                              indexOfData,
                                                              indexOfDemandSource,
                                                              indexOfCustomer
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "align-center",
                                                        staticStyle: {
                                                          display: "flex"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            staticStyle: {
                                                              "font-size":
                                                                "10px"
                                                            },
                                                            style: _vm.conduitHelper.dateIconForConduit(
                                                              indexOfData,
                                                              indexOfDemandSource,
                                                              indexOfCustomer
                                                            )
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-checkbox-blank-circle\n                    "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm
                                                                .util()
                                                                .formatDateString(
                                                                  customer.requiredDate
                                                                )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        "-" +
                                                          _vm._s(
                                                            _vm.parseIntHelper(
                                                              customer.originalSalesDemand
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.showOnSchedule(
                                                              customer.openOrderId,
                                                              customer.salesItemNumber,
                                                              demandSource.productNumber,
                                                              demandSource.productDescription
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.parseIntHelper(
                                                                customer.schedQtyTotal
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    ),
                                                    _c("div"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "details"
                                                      },
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: { lazy: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "ma-0",
                                                                            attrs: {
                                                                              small:
                                                                                "",
                                                                              flat:
                                                                                "",
                                                                              icon:
                                                                                "",
                                                                              color:
                                                                                "dark"
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "more_vert"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "v-list",
                                                              [
                                                                _c(
                                                                  "v-list-tile",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.viewConduitSpecs(
                                                                          indexOfData,
                                                                          indexOfDemandSource,
                                                                          indexOfCustomer
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-tile-title",
                                                                      [
                                                                        _vm._v(
                                                                          "View Product Specs"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.hasWriteAccess
                                                                  ? _c(
                                                                      "v-list-tile",
                                                                      {
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.scheduleProductConduit(
                                                                              indexOfData,
                                                                              indexOfDemandSource,
                                                                              indexOfCustomer
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm.hasWriteAccess
                                                                          ? _c(
                                                                              "v-list-tile-title",
                                                                              [
                                                                                _vm._v(
                                                                                  "Schedule Component"
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "t-row row-total" },
                                          [
                                            _c("div", [_vm._v("Total")]),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.parseIntHelper(
                                                    _vm.conduitHelper.getNetRequtedQtyTotal(
                                                      demandSource
                                                    )
                                                  )
                                                )
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.parseIntHelper(
                                                    _vm.conduitHelper.getNetSchedQtyTotal(
                                                      demandSource
                                                    )
                                                  )
                                                )
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "align-center",
                                                staticStyle: { display: "flex" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.parseIntHelper(
                                                        _vm.conduitHelper.getRemainToMakeTotal(
                                                          demandSource
                                                        )
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "ml-1",
                                                    style: _vm.conduitHelper.scheduledCheckIconConduit(
                                                      demandSource
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-disc\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "border-top": "1px solid #ccc" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          attrs: {
                            color: "white",
                            small: "",
                            tile: "",
                            depressed: ""
                          },
                          on: { click: _vm.collapseAll }
                        },
                        [_vm._v("Collapse All")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          attrs: {
                            color: "white",
                            small: "",
                            tile: "",
                            depressed: ""
                          },
                          on: { click: _vm.uncollapseAll }
                        },
                        [_vm._v("Expand All")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          staticStyle: { "min-width": "60px" },
                          attrs: {
                            small: "",
                            tile: "",
                            depressed: "",
                            color: _vm.isActive5,
                            disabled: _vm.isDisabledReqDate("5")
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleButton5()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "left",
                              staticStyle: {
                                color: "#f44336",
                                "font-size": "13px"
                              }
                            },
                            [_vm._v("mdi-checkbox-blank-circle ")]
                          ),
                          _vm._v(" <\n            5\n          ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          staticStyle: { "min-width": "60px" },
                          attrs: {
                            small: "",
                            tile: "",
                            depressed: "",
                            color: _vm.isActive5To15,
                            disabled: _vm.isDisabledReqDate("5-15")
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleButton5To15()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "left",
                              staticStyle: {
                                color: "#ff9800",
                                "font-size": "13px"
                              }
                            },
                            [_vm._v("mdi-checkbox-blank-circle ")]
                          ),
                          _vm._v(" 5-15\n          ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          staticStyle: { "min-width": "60px" },
                          attrs: {
                            small: "",
                            tile: "",
                            depressed: "",
                            color: _vm.isActive16To30,
                            disabled: _vm.isDisabledReqDate("16-30")
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleButton16To30()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "left",
                              staticStyle: {
                                color: "#4caf50",
                                "font-size": "13px"
                              }
                            },
                            [_vm._v("mdi-checkbox-blank-circle ")]
                          ),
                          _vm._v(" 16-30\n          ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          staticStyle: { "min-width": "60px" },
                          attrs: {
                            small: "",
                            tile: "",
                            depressed: "",
                            color: _vm.isActive30,
                            disabled: _vm.isDisabledReqDate("30")
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleButton30()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "left",
                              staticStyle: {
                                color: "#2196f3",
                                "font-size": "13px"
                              }
                            },
                            [_vm._v("mdi-checkbox-blank-circle ")]
                          ),
                          _vm._v(" >\n            30\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticStyle: { flex: "0 1 auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          staticStyle: { "text-transform": "none" },
                          attrs: {
                            small: "",
                            tile: "",
                            depressed: "",
                            color: _vm.isActiveProductionNeeded,
                            disabled: _vm.isDisabledInventory("needed")
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleButtonProductionNeeded()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "left",
                              staticStyle: { color: "#f44336" },
                              attrs: { small: "" }
                            },
                            [_vm._v("mdi-disc")]
                          ),
                          _vm._v(" Production Needed\n          ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          staticStyle: { "text-transform": "none" },
                          attrs: {
                            small: "",
                            tile: "",
                            depressed: "",
                            color: _vm.isActiveSurplus,
                            disabled: _vm.isDisabledInventory("surplus")
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleButtonSurplus()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "left",
                              staticStyle: { color: "#ff9800" },
                              attrs: { small: "" }
                            },
                            [_vm._v("mdi-disc")]
                          ),
                          _vm._v(" Surplus Inventory\n          ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          staticStyle: { "text-transform": "none" },
                          attrs: {
                            small: "",
                            tile: "",
                            depressed: "",
                            color: _vm.isActiveEqualsDemand,
                            disabled: _vm.isDisabledInventory("equals")
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleButtonEqualsDemand()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "left",
                              staticStyle: { color: "#4caf50" },
                              attrs: { small: "" }
                            },
                            [_vm._v("mdi-disc")]
                          ),
                          _vm._v(" Inventory = Demand\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ProductSpecsModal", {
        model: {
          value: _vm.showProductSpecsModal,
          callback: function($$v) {
            _vm.showProductSpecsModal = $$v
          },
          expression: "showProductSpecsModal"
        }
      }),
      _c("ScheduleModal", {
        on: { "save-demand-changes": _vm.saveDemandSummary },
        model: {
          value: _vm.showScheduleModal,
          callback: function($$v) {
            _vm.showScheduleModal = $$v
          },
          expression: "showScheduleModal"
        }
      }),
      _c("ScheduleProductModal", {
        attrs: {
          scheduleProduct: Object.assign({}, _vm.scheduleProductDto),
          passedProduct: Object.assign({}, _vm.savedScheduleProductDto),
          scheduleProductLines: [].concat(_vm.scheduleProductLines)
        },
        model: {
          value: _vm.showScheduleProductModal,
          callback: function($$v) {
            _vm.showScheduleProductModal = $$v
          },
          expression: "showScheduleProductModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }