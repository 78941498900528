var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { lazy: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ma-0",
                    attrs: { small: "", flat: "", icon: "", color: "dark" }
                  },
                  on
                ),
                [_c("v-icon", [_vm._v("more_vert")])],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-tile",
            { on: { click: _vm.viewProductSpecs } },
            [_c("v-list-tile-title", [_vm._v("View Product Specs")])],
            1
          ),
          _vm.hasWriteAccess
            ? _c(
                "v-list-tile",
                { on: { click: _vm.scheduleProduct } },
                [_c("v-list-tile-title", [_vm._v("Schedule Product")])],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }