var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticStyle: { margin: "24px" }, attrs: { column: "" } },
    [
      _c(
        "v-flex",
        { staticStyle: { "margin-bottom": "24px" } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Order Details")]),
              _vm.shouldDisplay
                ? _c("v-card-text", { staticStyle: { "padding-top": "6px" } }, [
                    _c("table", { staticClass: "table info_table" }, [
                      _c("thead"),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("Work Order")]),
                          _c("td", [_vm._v(_vm._s(_vm.workOrderNumber))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Customer")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.customerName))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Customer PO#")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.purchaseOrder))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Sales Order")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.salesOrder))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Ship-to-City")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.shipToCity))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Ship-to-State")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.currentOrderDetail.shipToState))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Requested Date")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(
                                  _vm.currentOrderDetail.requestedDate
                                )
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Requested Qty")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.parseHelper(_vm.info.quantity)))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Requested Qty (LB)")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.currentOrderDetail.requestedQuantityInLbs.toLocaleString()
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Scheduled Qty")]),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.currentProductionDetails != null
                                    ? _vm.currentProductionDetails
                                        .scheduledQuantity == null
                                      ? _vm.currentProductionDetails
                                          .updatedQuantityToProduce
                                      : _vm.currentProductionDetails
                                          .scheduledQuantity
                                    : ""
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Balance Remaining")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.currentProductionDetails != null
                                  ? _vm.currentProductionDetails
                                      .updatedQuantityToProduce
                                  : ""
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Production Line")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.productionLine))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Estimated Run Time")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.currentProductionDetails != null
                                  ? _vm.convertToDays(
                                      _vm.currentProductionDetails.hours
                                    )
                                  : ""
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Special Instructions")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.currentOrderDetail.specialInstructions)
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { staticStyle: { "margin-bottom": "24px" } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Production Run Details")]),
              _vm.shouldDisplay
                ? _c("v-card-text", { staticStyle: { "padding-top": "6px" } }, [
                    _c("table", { staticClass: "table info_table" }, [
                      _c("thead"),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("Run Rate")]),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.currentProductionDetails != null
                                    ? _vm.formatRunRate(
                                        _vm.currentProductionDetails
                                          .currentRunRate
                                      )
                                    : ""
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Qty to Produce (Ft)")]),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.currentProductionDetails != null
                                    ? _vm.currentProductionDetails
                                        .updatedQuantityToProduce
                                    : ""
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Qty to Produce (Lbs)")]),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.currentProductionDetails != null
                                    ? _vm.convertToFeet(
                                        _vm.currentProductionDetails
                                          .updatedQuantityToProduceNumber *
                                          _vm.currentProductionDetails
                                            .poundsPerFoot
                                      )
                                    : ""
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Estimated Start Date")]),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.currentProductionDetails != null
                                    ? _vm.formatDateStringWithTime(
                                        _vm.currentProductionDetails.runDate
                                      )
                                    : ""
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Estimated End Date")]),
                          _c("td", { style: _vm.productionIndicator() }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.currentProductionDetails != null
                                    ? _vm.getEstimatedEndDate(
                                        _vm.currentProductionDetails.runDate,
                                        _vm.currentProductionDetails.hours
                                      )
                                    : ""
                                ) +
                                "\n              "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { staticStyle: { "margin-bottom": "24px" } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Product Details")]),
              _vm.shouldDisplay
                ? _c("v-card-text", { staticStyle: { "padding-top": "6px" } }, [
                    _c("table", { staticClass: "table info_table" }, [
                      _c("thead"),
                      _c("tbody", [
                        _vm.isConduit
                          ? _c("tr", [
                              _c("td", [_vm._v("Finished Product")]),
                              _c("td", [_vm._v(_vm._s(_vm.finishedProduct))])
                            ])
                          : _vm._e(),
                        _c("tr", [
                          _c("td", [_vm._v("Product")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.product))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Product Number")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.currentProductSpecs.productNumber)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Product Spec")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.productSpec))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Primary Application")]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.info.primaryApplication))
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Secondary Application")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.currentProductSpecs.secondaryApplication
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Stripe")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.stripe))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Length Type")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.lengthType))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Cut Length")]),
                          _c("td", [_vm._v(_vm._s(_vm.cutLength))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("LBS / FT")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.lbsPerFt))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Min Wall")]),
                          _c("td", [_vm._v(_vm._s(_vm.minWall))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Max Wall")]),
                          _c("td", [_vm._v(_vm._s(_vm.maxWall))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Min OD")]),
                          _c("td", [_vm._v(_vm._s(_vm.minOd))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Nominal OD")]),
                          _c("td", [_vm._v(_vm._s(_vm.nominalOd))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Max OD")]),
                          _c("td", [_vm._v(_vm._s(_vm.maxOd))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Ovality")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.ovality))])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("OOR")]),
                          _c("td", [_vm._v(_vm._s(_vm.info.oor))])
                        ]),
                        _vm.currentProductionDetails != null
                          ? _c("tr", [
                              _c("td", [_vm._v("Reel Size")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentProductionDetails.conduitReelSize
                                  )
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.currentProductionDetails != null
                          ? _c("tr", [
                              _c("td", [_vm._v("Reel Type")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentProductionDetails.conduitReelType
                                  )
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }