import { render, staticRenderFns } from "./BasicProductionDetails.vue?vue&type=template&id=af46ddbc&"
import script from "./BasicProductionDetails.vue?vue&type=script&lang=ts&"
export * from "./BasicProductionDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\WlpScheduler\\front-end\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('af46ddbc')) {
      api.createRecord('af46ddbc', component.options)
    } else {
      api.reload('af46ddbc', component.options)
    }
    module.hot.accept("./BasicProductionDetails.vue?vue&type=template&id=af46ddbc&", function () {
      api.rerender('af46ddbc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/schedule/WorkOrderSteps/BasicProductionDetails.vue"
export default component.exports