var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        {
          staticClass: "py-1",
          class: _vm.viewOnly ? "blue" : "orange",
          staticStyle: { color: "#fff" }
        },
        [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.getDate) +
                " - " +
                _vm._s(_vm.facilityName) +
                " - " +
                _vm._s(_vm.facilityStartTime) +
                " "
            )
          ]),
          _vm.facilitySelected
            ? _c(
                "span",
                { staticClass: "d-flex align-center ml-1" },
                [
                  _vm.viewOnly
                    ? _c("span", [_vm._v(" - View Only")])
                    : _vm._e(),
                  _vm.editAccess
                    ? _c("span", [_vm._v(" - Editing")])
                    : _vm._e(),
                  _vm.viewOnly && !_vm.canEdit
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "px-3",
                          staticStyle: { "min-width": "70px" },
                          attrs: {
                            small: "",
                            round: "",
                            dark: "",
                            color: "orange"
                          },
                          on: {
                            click: function($event) {
                              return _vm.getScheduler("edit")
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    : _vm._e(),
                  _vm.editAccess
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "px-3",
                          staticStyle: { "min-width": "70px" },
                          attrs: {
                            small: "",
                            round: "",
                            dark: "",
                            color: "blue"
                          },
                          on: {
                            click: function($event) {
                              return _vm.getScheduler("view")
                            }
                          }
                        },
                        [_vm._v("Complete Editing")]
                      )
                    : _vm._e(),
                  _c("v-spacer")
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "text-transform": "none" },
                  attrs: { small: "", flat: "", color: "white" },
                  on: {
                    click: function($event) {
                      _vm.showMetrics = !_vm.showMetrics
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.statsButtonText) + "\n      "
                  )
                ]
              ),
              _vm.canScheduleMultiple
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ma-0 error--text",
                      attrs: { small: "", icon: "", color: "white" },
                      on: {
                        click: function($event) {
                          return _vm.toggleShowHomeScheduleModal()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-power")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.showMetrics
        ? _c(
            "v-card-text",
            { staticStyle: { "border-bottom": "1px solid #ccc" } },
            [
              _c("div", { staticClass: "dashboardMetrics" }, [
                _c("div", [
                  _c("h2", { staticClass: "metric red--text" }, [
                    _vm._v(_vm._s(_vm.lessThan5DaysCount))
                  ]),
                  _c(
                    "div",
                    { staticClass: "legend" },
                    [
                      _c("v-icon", { staticClass: "mr-2 red--text" }, [
                        _vm._v("mdi-checkbox-blank-circle")
                      ]),
                      _c("span", [_vm._v("Req Date < 5 Days")])
                    ],
                    1
                  )
                ]),
                _c("div", [
                  _c("h2", { staticClass: "metric orange--text" }, [
                    _vm._v(_vm._s(_vm.fiveTo15DaysCount))
                  ]),
                  _c(
                    "div",
                    { staticClass: "legend" },
                    [
                      _c("v-icon", { staticClass: "mr-2 orange--text" }, [
                        _vm._v("mdi-checkbox-blank-circle")
                      ]),
                      _c("span", [_vm._v("Req Date 5-15 Days")])
                    ],
                    1
                  )
                ]),
                _c("div", [
                  _c("h2", { staticClass: "metric green--text" }, [
                    _vm._v(_vm._s(_vm.sixteenTo30DaysCount))
                  ]),
                  _c(
                    "div",
                    { staticClass: "legend" },
                    [
                      _c("v-icon", { staticClass: "mr-2 green--text" }, [
                        _vm._v("mdi-checkbox-blank-circle")
                      ]),
                      _c("span", [_vm._v("Req Date 16-30 Days")])
                    ],
                    1
                  )
                ]),
                _c("div", [
                  _c("h2", { staticClass: "metric blue--text" }, [
                    _vm._v(_vm._s(_vm.greaterThan30DaysCount))
                  ]),
                  _c(
                    "div",
                    { staticClass: "legend" },
                    [
                      _c("v-icon", { staticClass: "mr-2 blue--text" }, [
                        _vm._v("mdi-checkbox-blank-circle")
                      ]),
                      _c("span", [_vm._v("Req Date > 30 Days")])
                    ],
                    1
                  )
                ]),
                _c("div", [
                  _c("h2", { staticClass: "metric red--text" }, [
                    _vm._v(_vm._s(_vm.productionNeededCount))
                  ]),
                  _c(
                    "div",
                    { staticClass: "legend" },
                    [
                      _c("v-icon", { staticClass: "mr-2 red--text larger" }, [
                        _vm._v("mdi-disc")
                      ]),
                      _c("span", [_vm._v("Production Needed")])
                    ],
                    1
                  )
                ]),
                _c("div", [
                  _c("h2", { staticClass: "metric orange--text" }, [
                    _vm._v(_vm._s(_vm.surplusInventoryCount))
                  ]),
                  _c(
                    "div",
                    { staticClass: "legend" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2 orange--text larger" },
                        [_vm._v("mdi-disc")]
                      ),
                      _c("span", [_vm._v("Surplus Inventory")])
                    ],
                    1
                  )
                ]),
                _c("div", [
                  _c("h2", { staticClass: "metric green--text" }, [
                    _vm._v(_vm._s(_vm.inventoryEqualsDemandCount))
                  ]),
                  _c(
                    "div",
                    { staticClass: "legend" },
                    [
                      _c("v-icon", { staticClass: "mr-2 green--text larger" }, [
                        _vm._v("mdi-disc")
                      ]),
                      _c("span", [_vm._v("Inventory = Demand")])
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _c("NoEditModal", {
        attrs: { name: _vm.editingName },
        model: {
          value: _vm.personEditModal,
          callback: function($$v) {
            _vm.personEditModal = $$v
          },
          expression: "personEditModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }