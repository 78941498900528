var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-form",
    {
      attrs: {
        title: "Edit Scheduled Item",
        "max-width": "600",
        "max-height": "600",
        persistent: "",
        "no-click-animation": ""
      },
      on: { "save-click": _vm.saveWorkOrderProduction },
      model: {
        value: _vm.showEditProduction,
        callback: function($$v) {
          _vm.showEditProduction = $$v
        },
        expression: "showEditProduction"
      }
    },
    [
      _c(
        "v-card",
        {
          staticStyle: { margin: "0 24px 24px" },
          attrs: { raised: "", elevation: "3" }
        },
        [
          _c("v-card-title", [_vm._v("Record Production")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "float",
                              label: "Production Qty",
                              required: "",
                              "validate-on-blur": true
                            },
                            model: {
                              value: _vm.production,
                              callback: function($$v) {
                                _vm.production = $$v
                              },
                              expression: "production"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-3" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label: "Production Date",
                                              readonly: ""
                                            },
                                            model: {
                                              value: _vm.date,
                                              callback: function($$v) {
                                                _vm.date = $$v
                                              },
                                              expression: "date"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menu,
                                callback: function($$v) {
                                  _vm.menu = $$v
                                },
                                expression: "menu"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                on: {
                                  input: function($event) {
                                    _vm.menu = false
                                  }
                                },
                                model: {
                                  value: _vm.date,
                                  callback: function($$v) {
                                    _vm.date = $$v
                                  },
                                  expression: "date"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-actions", { staticClass: "justify-end" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }