var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticStyle: { margin: "24px" },
          attrs: { raised: "", elevation: "3" }
        },
        [
          _c("v-card-title", [_vm._v("Work Order")]),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "topleft" },
              [
                _c(
                  "v-form",
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { grow: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.resinList,
                                "item-text": "name",
                                "item-value": "id",
                                label: "Natural Resins",
                                disabled: !_vm.hasWriteAccess,
                                rules: _vm.requiredRules,
                                "validate-on-blur": true
                              },
                              model: {
                                value: _vm.selectedResin,
                                callback: function($$v) {
                                  _vm.selectedResin = $$v
                                },
                                expression: "selectedResin"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { shrink: "", "pl-4": "" } },
                          [
                            _c("v-checkbox", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showFootageCheckbox,
                                  expression: "showFootageCheckbox"
                                }
                              ],
                              attrs: {
                                value: _vm.footageCheckbox,
                                label: "Include Footage Counter",
                                readonly: !_vm.hasWriteAccess
                              },
                              on: { change: _vm.updateFootageCheckbox }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("label", { staticClass: "grey--text caption" }, [
                      _vm._v("Print Line")
                    ]),
                    _c(
                      "v-alert",
                      {
                        staticClass: "mb-3",
                        attrs: { value: true, color: "#333" }
                      },
                      [_vm._v(_vm._s(_vm.printLine))]
                    ),
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c("v-switch", {
                          attrs: {
                            disabled: !_vm.hasWriteAccess,
                            color: "success",
                            label: "Preview XML"
                          },
                          model: {
                            value: _vm.showPreview,
                            callback: function($$v) {
                              _vm.showPreview = $$v
                            },
                            expression: "showPreview"
                          }
                        }),
                        _vm.failedToExportXml
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: !_vm.hasWriteAccess,
                                  color: "info"
                                },
                                on: { click: _vm.retryExport }
                              },
                              [_vm._v("Retry Work Order Export")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.shouldShowProductionAndXML && _vm.showPreview
                      ? _c("v-textarea", {
                          attrs: {
                            filled: "",
                            name: "input-7-4",
                            label: "Work Order XML",
                            value: _vm.workOrderXML,
                            "auto-grow": true,
                            readonly: true
                          }
                        })
                      : _vm._e(),
                    _c("label", { staticClass: "grey--text caption" }, [
                      _vm._v("Special Instructions")
                    ]),
                    _c("v-textarea", {
                      attrs: {
                        placeholder: _vm.specialInstructionsPlaceHolder,
                        filled: "",
                        name: "input-7-4",
                        "auto-grow": true,
                        readonly: !_vm.overrideSpecial,
                        disabled: !_vm.overrideSpecial,
                        rows: "2"
                      },
                      model: {
                        value: _vm.specialInstructions,
                        callback: function($$v) {
                          _vm.specialInstructions = $$v
                        },
                        expression: "specialInstructions"
                      }
                    }),
                    !_vm.override
                      ? _c("v-switch", {
                          attrs: {
                            disabled: !_vm.hasWriteAccess,
                            color: "success",
                            label: "Override"
                          },
                          model: {
                            value: _vm.overrideSpecial,
                            callback: function($$v) {
                              _vm.overrideSpecial = $$v
                            },
                            expression: "overrideSpecial"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.shouldShowProductionAndXML
        ? _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { sm6: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticStyle: { margin: "0 24px 24px" },
                      attrs: { raised: "", elevation: "3" }
                    },
                    [
                      _c("v-card-title", [_vm._v("Record Production")]),
                      _c(
                        "v-card-text",
                        [
                          _c("app-alert", {
                            attrs: {
                              backendErrorRecord: _vm.backendErrorRecord
                            }
                          }),
                          _c(
                            "v-form",
                            { ref: "form" },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label: "Production Qty",
                                          rules: _vm.rules.number,
                                          required: "",
                                          disabled: !_vm.hasWriteAccess
                                        },
                                        model: {
                                          value: _vm.recordedRate,
                                          callback: function($$v) {
                                            _vm.recordedRate = $$v
                                          },
                                          expression: "recordedRate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "pl-3" },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "290px"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            label:
                                                              "Production Date",
                                                            readonly: "",
                                                            disabled: !_vm.hasWriteAccess
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.productionDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.productionDate = $$v
                                                            },
                                                            expression:
                                                              "productionDate"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3687117997
                                          ),
                                          model: {
                                            value: _vm.menu,
                                            callback: function($$v) {
                                              _vm.menu = $$v
                                            },
                                            expression: "menu"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            on: {
                                              input: function($event) {
                                                _vm.menu = false
                                              }
                                            },
                                            model: {
                                              value: _vm.productionDate,
                                              callback: function($$v) {
                                                _vm.productionDate = $$v
                                              },
                                              expression: "productionDate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-end" },
                        [
                          _vm.hasWriteAccess
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "#333", dark: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.saveWorkOrderProduction.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Record")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { sm6: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticStyle: { margin: "0 24px 24px" },
                      attrs: { raised: "", elevation: "3" }
                    },
                    [
                      _c("v-card-title", [_vm._v("Previously Recorded")]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.allProductionRecords,
                              "item-key": "createdDate",
                              loading: _vm.loading,
                              "rows-per-page-items": [10]
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.parseHelper(
                                              props.item.recordedValue
                                            )
                                          )
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.createdDate))
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(props.item.productionDate)
                                        )
                                      ]),
                                      _vm.hasWriteAccess
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                loading: _vm.marking,
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openEditProduction(
                                                    props.item.recordedValue,
                                                    props.item.productionDate,
                                                    props.item.id
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Edit")]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4283135928
                            )
                          }),
                          _c(
                            "v-layout",
                            {
                              attrs: { row: "", "align-center": "", "mt-3": "" }
                            },
                            [
                              _c("v-flex", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      "Original Order Qty: " +
                                        _vm.parseHelper(
                                          _vm.info.scheduledQuantity
                                        )
                                    )
                                  )
                                ]),
                                _c("br"),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      "Total Recorded Qty: " +
                                        _vm.parseHelper(_vm.totalRecords)
                                    )
                                  )
                                ]),
                                _c("br"),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      "Remaining Balance: " +
                                        _vm.parseHelper(_vm.balanceRemaining)
                                    )
                                  )
                                ])
                              ]),
                              _c("v-flex", { staticClass: "text-xs-right" })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("EditProductionModal", {
                attrs: {
                  importedProduction: _vm.passProduction,
                  importedDate: _vm.passDate,
                  importedId: _vm.passId
                },
                on: { editProduction: _vm.editProduction }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }