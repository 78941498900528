var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-form",
    {
      attrs: {
        title: "Maintenance Downtime Hours",
        "max-width": "600",
        persistent: "",
        "no-click-animation": ""
      },
      on: {
        "save-click": _vm.checkValidHours,
        "esc-press": _vm.onEscPressed,
        "cancel-click": _vm.onCancelClicked
      },
      model: {
        value: _vm.showMaintenanceModal,
        callback: function($$v) {
          _vm.showMaintenanceModal = $$v
        },
        expression: "showMaintenanceModal"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "maintenanceHoursForm",
          attrs: { "lazy-validation": true },
          model: {
            value: _vm.isModalValid,
            callback: function($$v) {
              _vm.isModalValid = $$v
            },
            expression: "isModalValid"
          }
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-select", {
                attrs: {
                  rules: [
                    function(value) {
                      return !!value || "This field is required..."
                    }
                  ],
                  label: "Downtime Reason",
                  items: _vm.downtimeReasons,
                  "item-text": "reason",
                  "item-value": "id",
                  "validate-on-blur": true
                },
                model: {
                  value: _vm.selectedReason,
                  callback: function($$v) {
                    _vm.selectedReason = $$v
                  },
                  expression: "selectedReason"
                }
              }),
              _c("v-text-field", {
                attrs: { label: "Reason Note" },
                model: {
                  value: _vm.notes,
                  callback: function($$v) {
                    _vm.notes = $$v
                  },
                  expression: "notes"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  rules: _vm.greaterThan0,
                  type: "float",
                  label: "Hours",
                  required: ""
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.checkValidHours.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.selectedHours,
                  callback: function($$v) {
                    _vm.selectedHours = $$v
                  },
                  expression: "selectedHours"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }