var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "", lazy: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "red", dark: "" } },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" mdi-alert ")]
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v("Component Pending!")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }