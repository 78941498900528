import { render, staticRenderFns } from "./ScheduleBoardComponent.vue?vue&type=template&id=45817f9c&"
import script from "./ScheduleBoardComponent.vue?vue&type=script&lang=ts&"
export * from "./ScheduleBoardComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ScheduleBoardComponent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardTitle,VExpansionPanel,VExpansionPanelContent,VFlex,VIcon,VList,VListTile,VListTileTitle,VMenu,VProgressCircular,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\WlpScheduler\\front-end\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45817f9c')) {
      api.createRecord('45817f9c', component.options)
    } else {
      api.reload('45817f9c', component.options)
    }
    module.hot.accept("./ScheduleBoardComponent.vue?vue&type=template&id=45817f9c&", function () {
      api.rerender('45817f9c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/schedule/ScheduleBoardComponent.vue"
export default component.exports